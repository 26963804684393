import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

const dateFlatpickr = () => {
  document.addEventListener("turbolinks:load", () => {
    flatpickr(".datepicker", {
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      altFormat: "F j, Y H:i",
    });

    flatpickr(".datepicker-date-only", {
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "F j, Y",
    });
  });
};

export { dateFlatpickr };
